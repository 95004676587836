import {
  withValidation,
  assert,
  composeSDKFactories,
  reportError,
  messageTemplates,
  registerCorvidEvent,
} from '@wix/editor-elements-corvid-utils';

import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import { IMusicPlayerSDKFactory } from '../MusicPlayer.types';
import { isValidMediaSrc } from '../../../core/corvid/media/mediaSrcHandler';
import {
  createElementPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories/elementPropsSDKFactory';
import {
  createMediaItemUri,
  parseMediaItemUri,
} from '../../../core/corvid/media/mediaItemUtils';

const BASE_URL = 'https://music.wixstatic.com/mp3'; // TODO StaticMusicUrl
const BASE_MEDIA_URL = 'https://static.wixstatic.com/media';

const isValidSrc = (url: string) => {
  url = assert.isNil(url) ? '' : url;

  if (isValidMediaSrc(url, 'audio')) {
    return true;
  }
  reportError(
    messageTemplates.error_invalid_url({
      url,
      type: 'audio',
      prefix: 'wix:audio://',
    }),
  );
  return false;
};

const isValidCover = (url: string) => {
  url = assert.isNil(url) ? '' : url;

  if (isValidMediaSrc(url, 'image')) {
    return true;
  }

  reportError(
    messageTemplates.error_invalid_url({
      url,
      type: 'image',
      prefix: 'wix:image://',
    }),
  );
  return false;
};

const customRules = {
  src: [isValidSrc],
  coverImage: [isValidCover],
};
const externalUrl = /(^https?)|(^data)|(^blob)|(^\/\/)/;

const _musicPlayerSDKFactory: IMusicPlayerSDKFactory = api => {
  const { setProps, props, sdkData, compRef, metaData } = api;

  return {
    get artistName() {
      return props.playlist[0].artistName;
    },
    set artistName(value) {
      setProps({ playlist: [{ ...props.playlist[0], artistName: value }] });
    },
    get trackName() {
      return props.playlist[0].trackName;
    },
    set trackName(value) {
      setProps({ playlist: [{ ...props.playlist[0], trackName: value }] });
    },
    get coverImage() {
      if (props.rawCover) {
        return props.rawCover;
      }
      const { uri, height, width, title } = sdkData?.coverData || {};
      if (!uri) {
        return '';
      }

      if (externalUrl.test(uri)) {
        return uri;
      }

      const mediaItemUri = createMediaItemUri({
        mediaId: uri,
        width,
        height,
        title,
        type: 'image',
      });
      if (mediaItemUri.error) {
        return '';
      }
      return mediaItemUri.item || '';
    },
    set coverImage(value) {
      const mediaData = parseMediaItemUri(value);

      const cover =
        'mediaId' in mediaData
          ? `${BASE_MEDIA_URL}/${mediaData.mediaId}`
          : value;
      setProps({
        playlist: [{ ...props.playlist[0], cover }],
        rawCover: value,
      });
    },
    get src() {
      if (props.rawSrc) {
        return props.rawSrc;
      }

      const duration = props.duration ? Math.round(props.duration) : undefined;
      const { title, uri } = sdkData?.audioData || {};

      if (!uri) {
        return '';
      }

      if (externalUrl.test(uri)) {
        return uri;
      }

      const mediaItemUri = createMediaItemUri({
        mediaId: uri,
        duration,
        title,
        type: 'audio',
      });
      if (mediaItemUri.error) {
        return '';
      }
      return mediaItemUri.item || '';
    },
    set src(value) {
      const mediaData = parseMediaItemUri(value);

      const url =
        'mediaId' in mediaData ? `${BASE_URL}/${mediaData.mediaId}` : value;
      setProps({
        playlist: [{ ...props.playlist[0], url }],
        rawSrc: value,
        ...(mediaData.duration ? { duration: mediaData.duration } : {}),
      });
    },

    get currentTime() {
      return props.currentTime;
    },

    get duration() {
      return props.duration;
    },

    get volume() {
      return assert.isNil(props.volume) ? 100 : props.volume * 100;
    },

    set volume(value) {
      if (!assert.isNil(value)) {
        setProps({ volume: value / 100 });
      }
    },

    get isMuted() {
      return assert.isNil(props.muted) ? false : props.muted;
    },

    get isPlaying() {
      return assert.isNil(props.isPlaying) ? false : props.isPlaying;
    },
    mute() {
      return new Promise(resolve => {
        setProps({ muted: true });
        resolve();
      });
    },
    unmute() {
      return new Promise(resolve => {
        setProps({ muted: false });
        resolve();
      });
    },
    seek(time) {
      return compRef.seek(time);
    },
    play() {
      return compRef.play();
    },
    pause() {
      return compRef.pause();
    },
    stop() {
      compRef.pause();
      return compRef.seek(0);
    },
    togglePlay() {
      return compRef.togglePlay();
    },
    onPlay: handler => registerCorvidEvent('onPlay', api, handler),

    onPause: handler => registerCorvidEvent('onPause', api, handler),

    onEnded: handler => registerCorvidEvent('onEnded', api, handler),

    onProgress: handler => registerCorvidEvent('onTimeUpdated', api, handler),

    get type() {
      return '$w.MusicPlayer';
    },
    toJSON() {
      return {
        ...toJSONBase(metaData),
        type: '$w.MusicPlayer',
        artistName: props.playlist[0].artistName,
        trackName: props.playlist[0].trackName,
        volume: props.volume,
        isPlaying: props.isPlaying,
        isMuted: props.muted,
        currentTime: props.currentTime,
        duration: props.duration,
      };
    },
  };
};

const musicPlayerSDKFactory: IMusicPlayerSDKFactory = withValidation(
  _musicPlayerSDKFactory,
  {
    type: ['object'],
    properties: {
      artistName: { type: ['string', 'nil'], warnIfNil: true },
      trackName: { type: ['string', 'nil'], warnIfNil: true },
      coverImage: { type: ['string', 'nil'], warnIfNil: true },
      src: { type: ['string', 'nil'], warnIfNil: true },
      currentTime: { type: ['number', 'nil'], warnIfNil: true },
      duration: { type: ['number', 'nil'], warnIfNil: true },
      volume: {
        type: ['number', 'nil'],
        minimum: 0,
        maximum: 100,
        warnIfNil: true,
      },
      seek: { type: ['function'], args: [{ type: ['number'] }] },
    },
  },
  customRules,
);

const elementPropsSDKFactory = createElementPropsSDKFactory();

export const sdk: IMusicPlayerSDKFactory = composeSDKFactories(
  elementPropsSDKFactory,
  musicPlayerSDKFactory,
);
export default createComponentSDKModel(sdk);
